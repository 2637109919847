const ListFeature = ({ title, children }) => {
  return (
    <div className="bg-white my-5 px-7 py-12 w-96 h-56 lg:h-64 rounded-lg">
      <p className="uppercase text-center text-base lg:text-lg font-bold text-retala_yellow pb-3 border-opacity-50 border-b border-[#7a6d6d]">
        {title}
      </p>
      <p className="py-6 text-retala_blacker text-base lg:text-lg">
        {children}
      </p>
    </div>
  );
};

export default ListFeature;
