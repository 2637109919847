import ListAbout from "./components/listAbout";
import ListFeature from "./components/listFeature";
import img from "../../assets/picture.png";
import img2 from "../../assets/picture1.png";
import img3 from "../../assets/phone1.png";
import img4 from "../../assets/phone2.png";
import logo from "../../assets/logo.png";

const LandingPage = () => {
  return (
    <>
      <header className="flex items-center justify-between bg-[url('./assets/Container.png')] px-24 py-3">
        <img src={logo} alt="png logo" />
        <div className="hidden lg:flex gap-x-16 items-center text-retala_black">
          <a href="#features">Features</a>
          <a href="#products">Products</a>
          <a href="/">Blog</a>
          <a
            href="/"
            className="bg-retala_blue text-white rounded-lg py-1.5 px-9"
          >
            Contact Us
          </a>
        </div>
      </header>

      <section className="flex text-center lg:text-left gap-y-16 flex-col lg:flex-row items-center justify-between my-7 px-4 lg:px-28 gap-x-12 ">
        <article>
          <h2 className="text-2xl lg:text-5xl font-bold text-retala_yellow py-1">
            Pecuniary Technology
          </h2>
          <h2 className="text-2xl lg:text-5xl font-bold text-retala_blue capitalize pb-1">
            building software solutions to ease business challenges
          </h2>
          <p className="my-4 text-retala_black text-base lg:text-lg py-1">
            We are a technology company that aims to improve financial services
            such as Point Of Sales, through technology. We focus on improving
            financial literacy through our products and build software solutions
            for our customers that ease business challenges.
          </p>
          <a
            href="/"
            className="bg-retala_blue text-white rounded-lg text-base lg:text-xl font-medium my-6 py-2.5 px-9"
          >
            Read More
          </a>
        </article>
        <img src={img} alt="main pic" className="w-80 lg:w-96" />
      </section>

      <section id="features" className="my-36">
        <h2 className="text-3xl lg:text-5xl font-bold text-retala_blue text-center">
          Our Features
        </h2>
        <article className="flex gap-x-32 flex-wrap items-center justify-center bg-retala_lblue mt-6 py-14 lg:py-24 px-4 lg:px-28">
          <ListFeature title="technology solutions">
            We provide you with the solutions to your technologically related
            problems.
          </ListFeature>
          <ListFeature title="business  solutions">
            No matter what the business challenges might be, we provide
            solutions for them.
          </ListFeature>
          <ListFeature title="competitive edge">
            We provide you a significant jump ahead of your competitors.
          </ListFeature>
          <ListFeature title="worldclass support">
            Our support team is always available and willing to provide you with
            answers to all your questions.
          </ListFeature>
          <ListFeature title="user experience">
            Ease of use is our brand motto, so we make sure you have seamless
            experience using our services.
          </ListFeature>
          <ListFeature title="data backup">
            Your data is safe with us, all data are backed up and readily
            available whenever you need it.
          </ListFeature>
        </article>
      </section>

      <section className="my-36">
        <h2 className="text-3xl lg:text-5xl font-bold text-retala_blue text-center">
          About Us
        </h2>
        <article className="flex gap-x-32 flex-wrap items-center justify-center  mt-6 py-11 lg:py-24 px-4 lg:px-28">
          <article>
            <ListAbout title="our VALUES">
              Our customers are our foremost priority. Our inspiration comes
              from the challenges and difficulties businesses experience daily.
            </ListAbout>
            <ListAbout title="our Mission">
              To have a society advanced in finances and technology.
            </ListAbout>
            <ListAbout title="our Vision">
         To be one of the world’s leading solution providers in the finance and technology sectors. </ListAbout>
          </article>
          <img src={img2} alt="main pic" className="w-80 lg:w-max" />
        </article>
      </section>

      <section
        id="products"
        className="flex w-full h-full gap-x-20 py-12 lg:py-0 flex-col-reverse lg:flex-row gap-y-12  lg:justify-center bg-retala_lblue mt-6 px-4 lg:px-2"
      >
        <article className="lg:self-center lg:w-2/5">
          <h2 className="text-3xl lg:text-5xl font-bold text-retala_dark">
            Our Flagship Product
            <span className="text-retala_yellow pl-2">Retala</span>
          </h2>

          <p className="my-4 text-retala_black text-lg">
            Our first product is Retala: a business optimization software for
            small businesses that handles and monitors inventory, manages
            customers and employees, sales tax, standard reports, expenses, etc.
            It is affordable and easy to use with simple words, less multiple
            clicks, and stimulating colors for users to enjoy the experience.
          </p>
          <a
            href="https://retala.app/"
            target="_blank"
            rel="noreferrer"
            className="bg-retala_blue text-white rounded-lg text-lg lg:text-xl font-medium my-4 py-2.5 px-9"
          >
            Learn More
          </a>
        </article>

        <article className="grid grid-cols-2 lg:w-1/2">
          <img src={img3} alt="main pic" />
          <img src={img4} alt="main pic2" />
        </article>
      </section>

      <section className="grid  md:grid-cols-2 lg:grid-cols-[8fr_.8fr_5fr] gap-x-32 my-12 px-4 lg:px-24">
        <section>
          <h2 className="text-3xl lg:text-5xl font-bold text-retala_blue py-2">
            Contact us
          </h2>
          <img src={logo} alt="png logo" />
          <p className="text-sm text-retala_blue">
            Copyright © 2020 Pecuniary Financials.
          </p>
          <p className="text-sm text-retala_blue py-2">All rights reserved</p>
          <div className="flex gap-x-4 pt-6">
            <a href="https://www.instagram.com/retalapecksas"  target="_blank"
            rel="noreferrer">
              <i className="bi bi-instagram py-1 px-2 rounded-full text-lg bg-opacity-10 bg-retala_black"></i>
            </a>
            <a href="https://web.facebook.com/retalapecksas" target="_blank"
            rel="noreferrer">
              <i className="bi bi-facebook py-1 px-2 rounded-full text-lg bg-opacity-10 bg-retala_black"></i>
            </a>
            <a href="https://twitter.com/retalapecksas" target="_blank"
            rel="noreferrer">
              <i className="bi bi-twitter py-1 px-2 rounded-full text-lg bg-opacity-10 bg-retala_black"></i>
            </a>
            <a href="https://www.youtube.com/channel/UCjT42aKsxvjDGFpXvWtXCng" target="_blank"
            rel="noreferrer">
              <i className="bi bi-youtube py-1 px-2 rounded-full text-lg bg-opacity-10 bg-retala_black"></i>
            </a>
          </div>
        </section>

        <section>
          <p className="text-xl font-medium my-6">Company</p>
          <div className="text-retala_blue">
            <a href="/#" className="my-3  block">
              About us
            </a>
            <a href="/#" className="my-3 block">
              Blog
            </a>
            <a href="/#" className="my-3 block">
              Contact us
            </a>
     
          </div>
        </section>

        <section>
          <p className="text-xl font-medium my-6">Stay up to date</p>
          <div className="relative text-retala_blue">
            <input
              type="email"
              className="text-sm py-2.5 px-3 w-full outline-none rounded-lg bg-retala_black bg-opacity-20 text-retala_blue"
              placeholder="Stay up to date"
              name="emailInput"
              id="emailInput"
            />
            <i className="bi bi-send absolute top-1.5 right-5 text-lg text-retala_black"></i>
          </div>
        </section>
      </section>
    </>
  );
};

export default LandingPage;
