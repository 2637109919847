const ListAbout = ({ title, children }) => {
  return (
    <div className="bg-retala_lblue my-5 px-7 py-12 w-full lg:w-96 h-min lg:h-64 rounded-lg">
      <p className="uppercase text-base lg:text-lg font-bold text-retala_blue pb-3 border-opacity-50 border-b border-[#7f7d7d]">
        {title}
      </p>
      <p className="py-6 text-retala_blacker text-base lg:text-lg">
        {children}
      </p>
    </div>
  );
};

export default ListAbout;
