import "./App.css";

import LandingPage from "./pages/landingPage";

// import {Link} from "react-router-dom"

function App() {
  return (
    <>
      <LandingPage />
    </>
  );
}

export default App;
